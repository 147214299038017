import {
  QuoteExpandable,
  VesselRiderView,
} from '@digital-motors-boatyard/by-vessel-rider.component';
import {
  LengthUnit,
  VesselClass,
} from '@digital-motors-boatyard/common/dist/apis';
import {
  Condition,
  FinanceType,
} from '@digital-motors-boatyard/common/dist/enums';
import {
  AdditionalLeadDataInterface,
  AprTerm,
  DealSheet,
} from '@digital-motors-boatyard/common/dist/interfaces';
import { APR_TERMS } from '@digital-motors-boatyard/common-frontend/dist/constants';

import {
  CASH,
  FINANCE,
  NEW,
  USED,
  VESSEL_CLASSES,
  VESSEL_UNITS,
} from '../constants';
import { validTealiumEvents } from '../context/Analytics';

interface DefaultsDealerFees {
  id?: string;
  name: string;
  amount: number;
}

// Properties used to override normal tenant/dealer/vessel API lookups
export interface BoatyardDealSheetDefaults
  extends Pick<
    DealSheet,
    | 'dealerId'
    | 'year'
    | 'make'
    | 'model'
    | 'imageUrl'
    | 'customModelIncentives'
  > {
  oemCode: string;
  condition: Condition;
  dealerPrice: number; // consumed as both dealerPrice and dmSellingPrice
  basePrice: number; // consumed as totalMsrp for new inventory, and retailPrice for used
  vesselClass: VesselClass;
  vesselLength?: number;
  vesselLengthUnit?: LengthUnit;
  engineManufacturer?: string;
  totalEngines?: number;
  totalHorsepower?: number;
  mileage?: number;
  hin?: string;
  stockNumber?: string;
  customInventoryId?: string;
  financeType?: FinanceType;
  term?: AprTerm;
  downPayment?: number;
  creditRating?: string;
  returnWebsiteUrl?: string;
  additionalLeadData?: AdditionalLeadDataInterface[];
  dealerFees: DefaultsDealerFees[];
}

export interface BoatyardUser {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  contactEmail?: string;
  zipcode?: string;
}

export interface BoatyardData {
  tenantId: string;
  defaults: BoatyardDealSheetDefaults;
  user?: BoatyardUser;
}

export interface BoatyardLeadCallbackData {
  user: BoatyardUser;
  deal: Partial<DealSheet>;
  url: string;
  additionalData: { [key: string]: string };
}

export type BoatyardLeadCallback = (data: BoatyardLeadCallbackData) => void;

export type BoatyardAnalyticsCallback = (data: Record<string, string>) => void;

export interface Boatyard {
  vessel: BoatyardData | null;
  reEntry: [string, VesselRiderView, QuoteExpandable] | null;
  leadCallback: BoatyardLeadCallback | null;
  analyticsCallbacks: {
    [eventName: string]: BoatyardAnalyticsCallback | null;
  };
  openRider: (
    tenantId: string,
    defaults: BoatyardDealSheetDefaults,
    user?: BoatyardUser,
    leadCallback?: BoatyardLeadCallback
  ) => void;
  closeRider: () => void;
  registerLeadCallback: (leadCallback: BoatyardLeadCallback | null) => void;
  registerAnalyticsCallback: (
    eventName: string,
    callback: BoatyardAnalyticsCallback | null
  ) => void;
}

const paramError = (param: string, message?: string) => {
  console.error(
    'Vessel Rider',
    `Invalid "${param}".`,
    ...(message ? [message] : [])
  );
};

export const hasBoatyardUserData = (user?: BoatyardUser): boolean => {
  if (!user) return false;
  return !!user.phoneNumber || !!user.contactEmail;
};

let documentFontSize = '';
let bodyFontSize = '';
let bodyOverflow = '';

// This hook creates the "boatyard" window object that customers will use
// to launch vessel riders from their websites. We wrap it in a hook so
// we can easily access its data from within the app
export const useBoatyard = (): Boatyard | undefined => {
  if (typeof window.boatyard === 'undefined') {
    documentFontSize = document.documentElement.style.fontSize;
    bodyFontSize = document.body.style.fontSize;
    bodyOverflow = document.body.style.overflow;

    window.boatyard = {
      vessel: null,
      reEntry: null,
      leadCallback: null,
      analyticsCallbacks: {},
      openRider: (tenantId, defaults, user, leadCallback) => {
        // Validate params
        if (typeof tenantId !== 'string' || !tenantId) {
          paramError('tenantId');
        }
        if (defaults) {
          if (typeof defaults.dealerId !== 'string' || !defaults.dealerId) {
            paramError('dealerId');
          }
          if (![NEW, USED].includes(defaults.condition)) {
            paramError('condition', `Must be "${NEW}" or "${USED}".`);
          }
          if (
            typeof defaults.year !== 'number' ||
            !defaults.year ||
            defaults.year < 1
          ) {
            paramError('year');
          }
          if (typeof defaults.make !== 'string' || !defaults.make) {
            paramError('make');
          }
          if (typeof defaults.model !== 'string' || !defaults.model) {
            paramError('model');
          }
          if (defaults.oemCode && typeof defaults.oemCode !== 'string') {
            paramError('oemCode');
          }
          if (
            typeof defaults.vesselClass !== 'string' ||
            !defaults.vesselClass
          ) {
            paramError('vesselClass');
          }
          if (
            defaults.vesselClass &&
            !VESSEL_CLASSES.includes(defaults.vesselClass)
          ) {
            paramError(
              'vesselClass',
              `Must be one of ${VESSEL_CLASSES.map((t) => `"${t}"`).join(
                ', '
              )}.`
            );
          }
          if (typeof defaults.basePrice !== 'number' || !defaults.basePrice) {
            paramError('basePrice');
          }
          if (
            typeof defaults.dealerPrice !== 'number' ||
            !defaults.dealerPrice
          ) {
            paramError('dealerPrice');
          }

          if (typeof defaults.imageUrl !== 'string' || !defaults.imageUrl) {
            paramError('imageUrl');
          }

          if ('vesselLength' in defaults && defaults.vesselLength) {
            if (
              !defaults.vesselLength ||
              typeof defaults.vesselLength !== 'number'
            ) {
              paramError('vesselLength');
            }
            if (!defaults.vesselLengthUnit) {
              paramError('vesselLength', 'Must also include vesselLengthUnit.');
            }
            if (
              defaults.vesselLengthUnit === LengthUnit.INCH &&
              defaults.vesselLength % 1
            ) {
              paramError('vesselLength', 'Inches must be a whole number.');
            }
          }
          if (defaults.vesselLengthUnit) {
            if (!VESSEL_UNITS.includes(defaults.vesselLengthUnit)) {
              paramError(
                'vesselLengthUnit',
                `Must be one of ${VESSEL_UNITS.map((t) => `"${t}"`).join(
                  ', '
                )}.`
              );
            }
            if (!defaults.vesselLength) {
              paramError('vesselLengthUnit', 'Must also include vesselLength.');
            }
          }
          if (
            defaults.engineManufacturer &&
            typeof defaults.engineManufacturer !== 'string'
          ) {
            paramError('engineManufacturer');
          }
          if (
            defaults.totalEngines &&
            (typeof defaults.totalEngines !== 'number' ||
              !Number.isInteger(defaults.totalEngines))
          ) {
            paramError('totalEngines', 'Must be an integer');
          }
          if (
            defaults.totalHorsepower &&
            typeof defaults.totalHorsepower !== 'number'
          ) {
            paramError('totalHorsepower', 'Must be a number');
          }
          if (
            defaults.mileage &&
            (typeof defaults.mileage !== 'number' ||
              !Number.isInteger(defaults.mileage))
          ) {
            paramError('mileage', 'Must be an integer');
          }
          if (
            'downPayment' in defaults &&
            (typeof defaults.downPayment !== 'number' ||
              !Number.isInteger(defaults.downPayment) ||
              defaults.downPayment < 0)
          ) {
            paramError(
              'downPayment',
              `Must be an integer greater than or equal to 0.`
            );
          }
          if (defaults.hin && typeof defaults.hin !== 'string') {
            paramError('hin');
          }
          if (
            defaults.stockNumber &&
            typeof defaults.stockNumber !== 'string'
          ) {
            paramError('stockNumber');
          }
          if (
            defaults.customInventoryId &&
            typeof defaults.customInventoryId !== 'string'
          ) {
            paramError('customInventoryId');
          }
          // @ts-ignore
          if ('term' in defaults && !APR_TERMS.includes(defaults.term)) {
            paramError(
              'term',
              `Must be one of ${APR_TERMS.map((t) => `"${t}"`).join(', ')}.`
            );
          }
          if (
            'financeType' in defaults &&
            // @ts-ignore
            !Object.values(FinanceType).includes(defaults.financeType)
          ) {
            paramError('financeType', `Must be "${FINANCE}" or "${CASH}".`);
          }
          if (
            'creditRating' in defaults &&
            typeof defaults.creditRating !== 'string'
          ) {
            paramError('creditRating');
          }
          if (
            defaults.returnWebsiteUrl &&
            typeof defaults.returnWebsiteUrl !== 'string'
          ) {
            paramError('returnWebsiteUrl');
          }

          if ('dealerFees' in defaults) {
            if (!Array.isArray(defaults.dealerFees)) {
              paramError('dealerFees', `dealerFees must be an array of fees.`);
            } else {
              for (const fee of defaults.dealerFees) {
                const name = fee.name;
                const amount = Number(fee.amount);
                if (!name || !amount) {
                  // Don't display any fee if any of the given fees are not parsable
                  defaults.dealerFees = [];
                  break;
                }
              }
            }
          }

          if ('customModelIncentives' in defaults) {
            if (!Array.isArray(defaults.customModelIncentives)) {
              paramError(
                'customModelIncentives',
                'customModelIncentives must be an array of fees.'
              );
            } else {
              for (const customModelIncentive of defaults.customModelIncentives) {
                const name = customModelIncentive.name;
                const amount = Number(customModelIncentive.amount);
                if (!name || !amount) {
                  // Don't allow any if any of the given attributes are not parsable.
                  defaults.customModelIncentives = [];
                  break;
                }
              }
            }
          }
        }

        // Assign vessel data
        // @ts-ignore
        window.boatyard.vessel = {
          tenantId,
          defaults,
          user: hasBoatyardUserData(user) ? user : undefined,
        };
        if (typeof leadCallback !== 'undefined') {
          // @ts-ignore
          window.boatyard.leadCallback = leadCallback;
        }
        // @ts-ignore
        document.documentElement.style.fontSize = '16px';
        document.body.style.fontSize = '16px';
        document.body.style.overflow = 'hidden';
      },
      closeRider: () => {
        // @ts-ignore
        window.boatyard.vessel = null;
        // @ts-ignore
        window.boatyard.reEntry = null;
        document.documentElement.style.fontSize = documentFontSize;
        document.body.style.fontSize = bodyFontSize;
        document.body.style.overflow = bodyOverflow;
      },
      registerLeadCallback: (leadCallback) => {
        // @ts-ignore
        window.boatyard.leadCallback = leadCallback;
      },
      registerAnalyticsCallback: (eventName, callback) => {
        if (validTealiumEvents.includes(eventName)) {
          // @ts-ignore
          window.boatyard.analyticsCallbacks[eventName] = callback;
        } else {
          console.error(
            'Failed to register analytics callback, invalid event name:',
            eventName
          );
        }
      },
    } as Boatyard;
  }

  return window.boatyard;
};
